import { readableColor } from 'polished'
import { ButtonHTMLAttributes } from 'react'
import { motion, useSpring, useMotionTemplate } from 'framer-motion'

import styles from './Button.module.css'

const clipDepth = 18

interface ButtonProps
  extends Pick<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'type' | 'className' | 'children' | 'disabled' | 'onClick'
  > {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'brand'
  wide?: boolean
  loading?: boolean
  clipSide?: 'left' | 'right'
  backgroundColor?: string
}

export function Button(props: ButtonProps) {
  const depth = useSpring(clipDepth, {
    stiffness: 500,
    damping: 20
  })
  const clipPath = useMotionTemplate`polygon(${depth}px 0,100% 0,100% 100%,0 100%,0 ${depth}px)`
  const clipPathRight = useMotionTemplate`polygon(calc(100% - ${depth}px) 0, 100% calc(0% + ${depth}px), 100% 100%, 0 100%, 0 0)`

  let className = styles.button
  switch (props.variant) {
    case 'primary':
      className += ` ${styles.primary}`
      break
    case 'secondary':
      className += ` ${styles.secondary}`
      break
    case 'brand':
      className += ` ${styles.brand}`
      break
    case 'tertiary':
      className += ` ${styles.tertiary}`
      break
    default:
      className += ` ${styles.primary}`
  }

  if (props.wide) className += ` ${styles.wide}`
  if (props.loading) className += ' animate-pulse'

  return (
    <motion.button
      className={className}
      transition={{
        stiffness: 0,
        velocity: 2000,
        duration: 0.1,
        damping: 10
      }}
      onHoverStart={() => !props.disabled && depth.set(0)}
      onHoverEnd={() => !props.disabled && depth.set(clipDepth)}
      whileHover={{
        scale: 1.03
      }}
      whileTap={{
        scale: 0.97,
        backgroundColor: '#fff'
      }}
      style={{
        clipPath: props.clipSide === 'right' ? clipPathRight : clipPath,
        ...(props.backgroundColor
          ? {
              backgroundColor: props.backgroundColor,
              color: readableColor(props.backgroundColor),
              '--tw-ring-color': props.backgroundColor
            }
          : {})
      }}
      {...props}
    />
  )
}
